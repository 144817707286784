import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Modal from "../../modal";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStudyPlan } from "../../../contexts/study.context";
import { makeStyles } from "@material-ui/core/styles";

const DeleteCountryModal = ({
  countryId,
  siteGroup,
  open = false,
  onClose,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const { removeCountry, deleteCountryLoading } = useStudyPlan();

  const handleDelete = () => {
    const body = {
      studyPlanId: siteGroup.studyPlanId,
      siteGroupId: siteGroup.siteGroupId,
    };

    removeCountry(body, siteGroup.studyId, countryId, onClose);

    //after country is removed wer want to go to first
    //country available and refresh the study as the milestones are going to adjust for all countries
    history.push(
      `/ctpo/study-plans/${siteGroup.studyId}/${siteGroup.studyPlanId}/countries-and-site-groups?country=0`,
    );
    window.location.reload();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Delete country"
      aria-describedby="Delete a country"
      title="Delete Country"
    >
      <Container>
        <Typography>
          Are you sure you want to remove this country from your study plan?
          This action cannot be undone and any changed data will be lost.
        </Typography>

        <ButtonContainer>
          <Button
            className={classes.secondaryButton}
            onClick={onClose}
            disabled={deleteCountryLoading}
          >
            Cancel
          </Button>
          <Button
            className={classes.primaryButton}
            onClick={handleDelete}
            disabled={deleteCountryLoading}
          >
            {deleteCountryLoading ? (
              <CircularProgress size="1em" />
            ) : (
              "Remove Country"
            )}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default DeleteCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));
