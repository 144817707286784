import Button from "@material-ui/core/Button";
import React, { useState, useMemo } from "react";
import XLSX from "xlsx-js-style";
import {
  BodyStyle,
  DEFAULT_DATE_CONFIG,
  HeaderStyle1,
  getCellType,
  getDateObject,
} from "../../../app/contexts/helpers";
import { getComparator } from "../../../app/helpers/utils";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const SITES_INCLUDED_HEADERS = [
  "Project ID",
  "Country",
  "Central / Local",
  "Site Number - PI",
  "Site Selected Date",
  "Site Activated - Actual",
  "Site Activated - Forecast",
  "Site Status",
];
export const ExportTablesButton = ({
  stableSort,
  remodelSitesFilteredIncluded,
  order,
  orderBy,
  remodelSitesFilteredNotIncluded,
  remodelSitesFilteredIncludedClosed,
  remodelSitesIncluded,
  remodelSitesIncludedClosed,
  remodelSitesNotIncluded,
}) => {
  const classes = useStyles();
  const [working, setWorking] = useState(false);

  const { id } = useParams();

  const filename_base = useMemo(
    () =>
      `${"sites_included_and_not_included_in_remodel"}_${new Date().toString()}_${id}`,
    [id],
  );
  const configureSheetColumns = (content) => {
    if (content.length > 0) {
      return content[0].map((_, idx) =>
        idx === 3 ? { width: 40 } : idx === 7 ? { width: 30 } : { width: 20 },
      );
    }
    return [];
  };

  const addSheetToBook = (wb, content, sheetName, dateConfig) => {
    const sheet = XLSX.utils.aoa_to_sheet(content, dateConfig);
    const sheetCols = configureSheetColumns(content);
    if (sheetCols.length > 0) {
      sheet["!cols"] = sheetCols;
    }
    XLSX.utils.book_append_sheet(wb, sheet, sheetName);
  };

  const styleTableContent = (tableContent) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value ?? "",
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s: i === 0 ? HeaderStyle1 : BodyStyle,
        });
      }
    }
    return styledTableContent;
  };

  const download_sites_included = () => {
    return styleTableContent([
      SITES_INCLUDED_HEADERS,
      ...stableSort(remodelSitesFilteredIncluded, getComparator(order, orderBy))
        .slice(0)
        .map((si, idx) => {
          return [
            si["Project ID"] ?? "",
            si.Country ?? "",
            si["Central / Local"] ?? "",
            si["Site Number - PI"] ?? "",
            getDateObject(si["Site Selected Date"]) ?? "",
            getDateObject(si["Site Activated - Actual"]) ?? "",
            getDateObject(si["Site Activated - Forecast"]) ?? "",
            si["Site Status"] ?? "",
            // idx === 0 ? si.Total : "",
          ];
        }),
    ]);
  };

  const download_sites_not_included = () => {
    return styleTableContent([
      SITES_INCLUDED_HEADERS,
      ...stableSort(
        remodelSitesFilteredNotIncluded,
        getComparator(order, orderBy),
      )
        .slice(0)
        .map((si, idx) => {
          return [
            si["Project ID"] ?? "",
            si.Country ?? "",
            si["Central / Local"] ?? "",
            si["Site Number - PI"] ?? "",
            getDateObject(si["Site Selected Date"]) ?? "",
            getDateObject(si["Site Activated - Actual"]) ?? "",
            getDateObject(si["Site Activated - Forecast"]) ?? "",
            si["Site Status"] ?? "",
            // idx === 0 ? si.Total : "",
          ];
        }),
    ]);
  };

  const download_sites_included_closed = () => {
    return styleTableContent([
      SITES_INCLUDED_HEADERS,
      ...stableSort(
        remodelSitesFilteredIncludedClosed,
        getComparator(order, orderBy),
      )
        .slice(0)
        .map((si, idx) => {
          return [
            si["Project ID"] ?? "",
            si.Country ?? "",
            si["Central / Local"] ?? "",
            si["Site Number - PI"] ?? "",
            getDateObject(si["Site Selected Date"]) ?? "",
            getDateObject(si["Site Activated - Actual"]) ?? "",
            getDateObject(si["Site Activated - Forecast"]) ?? "",
            si["Site Status"] ?? "",
            // idx === 0 ? si.Total : "",
          ];
        }),
    ]);
  };

  const downloadSitesIncludedContent = () => download_sites_included();
  const downloadSitesIncludedClosedContent = () =>
    download_sites_included_closed();
  const downloadSitesNotIncludedContent = () => download_sites_not_included();

  const exportTables = () => {
    setWorking(true);
    try {
      const wb = XLSX.utils.book_new();

      const sitesIncludedContent = downloadSitesIncludedContent();
      addSheetToBook(
        wb,
        sitesIncludedContent,
        "Sites Included in Remodel",
        DEFAULT_DATE_CONFIG,
      );

      const sitesIncludedClosedContent = downloadSitesIncludedClosedContent();
      addSheetToBook(
        wb,
        sitesIncludedClosedContent,
        "Closed Sites in Remodel",
        DEFAULT_DATE_CONFIG,
      );

      const sitesNotIncludedContent = downloadSitesNotIncludedContent();
      addSheetToBook(
        wb,
        sitesNotIncludedContent,
        "Sites Not Included in Remodel",
        DEFAULT_DATE_CONFIG,
      );

      XLSX.writeFile(wb, `${filename_base}.xlsx`, XLSX.cellDates);
    } catch (err) {
      console.log(err);
    }
    setWorking(false);
  };

  return (
    <Button
      disabled={working}
      onClick={() => exportTables()}
      className={classes.primaryButton}
      style={{ width: "fit-content" }}
    >
      {remodelSitesIncluded.length > remodelSitesFilteredIncluded.length ||
      remodelSitesIncludedClosed.length >
        remodelSitesFilteredIncludedClosed.length ||
      remodelSitesNotIncluded.length > remodelSitesFilteredNotIncluded.length
        ? "Export Filtered Tables"
        : "Export Tables"}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));
