import { CircularProgress, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Modal } from "../../../../app/components";
import { nanoid } from "nanoid";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { makeStyles } from "@material-ui/styles";
import { EditScheduleEventForm } from "./edit.schedule.events.form";
/*
  This component is a modal that allows the user to edit a schedule of events.
  It uses the schedule events context to handle the editing of the schedule of events.
  The parent component must pass a callback function to handle the schedule of events parameters.
*/
const EditScheduleOfEventModal = ({ soe, ...props }) => {
  const classes = useStyles();
  const [reRenderForm, setReRenderForm] = useState(nanoid());

  const { scheduleOfEvents, fetchASingleSOE, savingSOEStudy } =
    useScheduleEvents();
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [SOEEventParameters, setSOEEventParameters] = useState({
    scheduleOfEventsName: soe?.scheduleOfEventsName,
    scheduleOfEventsDescr: soe?.scheduleOfEventsDescr,
    scheduleOfEventsStatus: soe?.scheduleOfEventsStatus,
    studyPlanId: soe?.studyPlanId,
    visitIntervalType: soe?.visitIntervalType,
    visitWindowIntervalType: soe?.visitWindowIntervalType,
    CDAReviewPctTime: soe?.CDAReviewPctTime,
    assessorName: soe?.assessorName,
    AddlTimeOnSitePct: soe?.AddlTimeOnSitePct,
    EstActivatedNonEnrollingSitesPct: soe.EstActivatedNonEnrollingSitesPct,
    ReqAdminIMVNonEnrollingSites: soe.ReqAdminIMVNonEnrollingSites,
    SubjectReportingAvgFrequency: soe.SubjectReportingAvgFrequency,
    SubjectReportingHoursToGenerate: soe.SubjectReportingHoursToGenerate,
    StudyReportingAvgFrequency: soe.StudyReportingAvgFrequency,
    StudyReportingHoursToGenerate: soe.StudyReportingHoursToGenerate,
    StudyAdhocReview: soe.StudyAdhocReview,
  });
  const [errors, setErrors] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
    visitIntervalType: "",
    visitWindowIntervalType: "",
    CDAReviewPctTime: "",
  });

  useEffect(() => {
    const { scheduleOfEventsId } = soe;
    if (scheduleOfEventsId) {
      (async () => {
        setIsLoading(true);
        const res = await fetchASingleSOE(scheduleOfEventsId);
        if (res.data && res.data.length) {
          setSOEEventParameters(res.data[0]);
          setTimeout(() => {
            setReRenderForm(nanoid());
            setIsLoading(false);
          }, 400);
        } else setIsLoading(true);
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      SOEEventParameters["scheduleOfEventsDescr"] &&
      SOEEventParameters["scheduleOfEventsName"] &&
      SOEEventParameters["visitIntervalType"] &&
      SOEEventParameters["visitWindowIntervalType"] &&
      SOEEventParameters["CDAReviewPctTime"] &&
      SOEEventParameters["protocolVersion"] &&
      SOEEventParameters["protocolDate"] &&
      !errors["scheduleOfEventsDescr"] &&
      !errors["scheduleOfEventsName"] &&
      !errors["visitIntervalType"] &&
      !errors["visitWindowIntervalType"] &&
      !errors["CDAReviewPctTime"] &&
      !errors["protocolVersion"] &&
      !errors["protocolDate"]
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [SOEEventParameters, errors]);

  const handleChange = (key, value) => {
    setFormTouched(true);
    setSOEEventParameters({ ...SOEEventParameters, [key]: value });
    if (!value && key !== "StudyPlanId") {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
    if (key === "scheduleOfEventsName") {
      scheduleOfEvents.forEach((sp) => {
        if (sp.scheduleOfEventsId === soe.scheduleOfEventsId) return;
        if (
          sp?.scheduleOfEventsName.toLowerCase().trim() ===
          value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Schedule of Events name must be unique",
          });
        }
        if (!value.replace(/\s/g, "").length) {
          setErrors({
            ...errors,
            [key]: "SOE name must contain at least 1 character",
          });
        }
      });
    }
    if (key === "scheduleOfEventsDescr") {
      if (!value.replace(/\s/g, "").length) {
        setErrors({
          ...errors,
          [key]: "SOE description must contain at least 1 character",
        });
      }
    }
  };

  return (
    <Modal
      size={"80%"}
      maxWidth={"1200px"}
      {...props}
      aria-labelledby="Edit Schedule of Events"
      aria-describedby="Create a new schedule of events"
      title="Edit Schedule of Events"
    >
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Container key={reRenderForm}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <EditScheduleEventForm
              handleChange={handleChange}
              errors={errors}
              SOEEventParameters={SOEEventParameters}
            />
          </div>
          <ButtonContainer>
            <Button
              className={classes.secondaryButton}
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={savingSOEStudy || !formValid || !formTouched}
              className={classes.primaryButton}
              onClick={() =>
                props.onContinue({ ...soe, ...SOEEventParameters })
              }
            >
              {savingSOEStudy && <CircularProgress size="1em" />}
              {!savingSOEStudy && "Save"}
            </Button>
          </ButtonContainer>
        </Container>
      )}
    </Modal>
  );
};

export default EditScheduleOfEventModal;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-inline: 1em;
  max-height: 75vh;
  overflow-y: auto;

  // add a custom scrollbar style here

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  background-color: white;
  with: 100%;
  position: sticky;
  bottom: -13px;
  z-index: 10;
  padding: 1rem 0rem;
`;
