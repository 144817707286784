import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { CountryHeader } from "../../../../app/components";
import CountryInputs from "./country.inputs";
import CountryGlobalMilestones from "./country.global.milestones";
import CountryActivationProfiles from "./country.activation.profiles";
import CountryEnrollmentSeasonalVariations from "./country.enrollment.seasonal.variations";
import SiteFatigue from "./site.fatigue";
import SepareteContainer from "../../../../app/components/separete.Container";

const CountryPanel = ({ value, id, country }) => {
  const { updatePlan, studyPlan } = useStudyPlan();

  const handleDelete = () => {
    const { countries } = studyPlan;

    const index = countries
      .map((country) => country.countryId)
      .indexOf(country.countryId);

    countries.splice(index, 1);

    updatePlan("countries", countries);
  };

  const siteGroup = country.siteGroups[0];
  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={`scrollable-auto-tabpanel-${id}`}
    >
      {value === id && (
        <Container>
          <SepareteContainer>
            <CountryHeader
              title={`${country.countryName} - ${country.countryCodeISO3}`}
              onClick={handleDelete}
              countryId={country.countryId}
              studyPlanType={studyPlan.studyPlanType}
              siteGroup={siteGroup}
              hasDelete={false}
              hasEdit={false}
              tab={value}
            />
            <CountryInputs
              country={country}
              siteGroup={siteGroup}
              tab={value}
            />
          </SepareteContainer>
          <br />
          <CountryGlobalMilestones
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
          <br />
          <CountryActivationProfiles
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
          <br />
          <CountryEnrollmentSeasonalVariations
            tab={value}
            country={country}
            siteGroup={siteGroup}
          />
          <br />
          <SiteFatigue
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
        </Container>
      )}
    </div>
  );
};

export default CountryPanel;

const Container = styled.div`
  padding: 1em 0;
  display: grid;
  gap: 1em;
`;
