import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CloneStudyPlan from "./clone.study.plan";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MuiTableRow from "@material-ui/core/TableRow";
import OptionsMenu from "./options.menu";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { otherStyles } from "../../../app/helpers/utils.js";
import { withStyles } from "@material-ui/core";
import { DeleteStudyPlanModal } from "./delete.study.plan.modal/delete.study.plan.modal.js";

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).utc().format("DD/MMM/YYYY") : "";
};

const formatDateTime = (date) => {
  return moment(date).isValid()
    ? moment(date).utc().format("DD/MMM/YYYY HH:mm A (UTC)")
    : "";
};

const TableRow = (props) => {
  const {
    studyPlanId,
    studyPlanName,
    insertBy,
    insertDate,
    scenarioStatus,
    simOptRunId,
    studyPlanDescr,
    studyPlans,
    studyPlanTypeDetail,
    updateBy,
    updateDate,
  } = props;

  const classes = useStyles();
  const otherClasses = otherStyles();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const cells = [
    { content: studyPlanDescr },
    { content: scenarioStatus },
    { content: studyPlanTypeDetail },
    { content: insertBy },
    {
      content: formatDate(insertDate),
      title: formatDateTime(insertDate),
      noWrap: true,
    },
    { content: updateBy },
    {
      content: formatDate(updateDate),
      title: formatDateTime(updateDate),
      noWrap: true,
    },
  ];

  const StyledTableCell = withStyles((theme) => ({
    root: {
      paddingLeft: "5px",
      paddingRight: "5px",
      color: "var(--Secondary-color)",

      "&:first-child": {
        paddingLeft: "10px",
      },

      fontWeight: "bold",

      "&:hover": {
        fontWeight: "bold",
        opacity: 0.7,
        color: "var(--Secondary-color)",
      },
    },
  }))(TableCell);

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickStudy = () => {
    history.push(`/ctpo/study-plans/${id}/${studyPlanId}/review`);
  };

  return (
    <MuiTableRow>
      <StyledTableCell align="center"></StyledTableCell>
      <StyledTableCell
        className={otherClasses.link}
        onClick={handleClickStudy}
        align="left"
      >
        {studyPlanName}
      </StyledTableCell>
      {cells.map((cell, index) => (
        <TableCell
          key={index}
          align="left"
          style={{ padding: 5 }}
          onClick={cell.onClick}
          className={cell.className}
        >
          <Typography
            noWrap={cell.noWrap}
            title={cell.title}
          >
            {cell.content}
          </Typography>
        </TableCell>
      ))}
      <TableCell
        align="right"
        padding="checkbox"
      >
        <IconButton onClick={handleClick}>
          <MoreVertIcon classes={classes} />
        </IconButton>
        <OptionsMenu
          open={open}
          anchor={anchor}
          studyPlanId={studyPlanId}
          simOptRunId={simOptRunId}
          scenarioStatus={scenarioStatus}
          studyPlanTypeDetail={studyPlanTypeDetail}
          onClone={() => setOpenModal(true)}
          onClose={() => setOpen(false)}
          onDelete={() => setDeleteOpen(true)}
        />
        {openModal && (
          <CloneStudyPlan
            key={`${studyPlanId}-${openModal}`}
            open={openModal}
            onClose={() => setOpenModal(false)}
            plan={{ studyPlanName, studyPlanId, studyPlans }}
          />
        )}
        {deleteOpen && (
          <DeleteStudyPlanModal
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            scenarioStatus={scenarioStatus}
            studyPlanId={studyPlanId}
          />
        )}
      </TableCell>
    </MuiTableRow>
  );
};

export default TableRow;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#757072",
  },
}));
