import React from "react";
import styled from "styled-components";
import { ReactComponent as ReactLogo } from "./logo.svg";
import remarque_white from "../../../images/remarque_white.png";

const Logo = () => (
  <img
    src={remarque_white}
    alt="Remarque"
    height="51px"
  />
);

export default Logo;

