import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/styles";

export const CloseButton = ({ setRemodelViewOpen }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={() => setRemodelViewOpen(false)}
      className={classes.secondaryButton}
      style={{ width: "fit-content", padding: "5px" }}
    >
      Close
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));
