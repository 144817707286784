import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import EditIcon from "@material-ui/icons/Edit";
import Label from "./label";
import moment from "moment";
import { SectionHeader } from "../../../../app/components";
import SepareteContainer from "../../../../app/components/separete.Container";

const PrimaryDetails = () => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { studyPlan, canEditPlan } = useStudyPlan();

  const {
    studyPlanName,
    studyPlanDescr,
    croProjectStartDateMillis,
    finalProtocolStartDateMillis,
    scenarioStatus,
    studyPlanTypeDetail,
  } = studyPlan;

  const startDate = moment(croProjectStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");
  const protocolDate = moment(finalProtocolStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");
  const sections = [
    {
      items: [
        { label: "Study Plan Name", value: studyPlanName },
        { label: "Description", value: studyPlanDescr },
      ],
    },
    {
      items: [
        { label: "Status", value: scenarioStatus },
        { label: "Type", value: studyPlanTypeDetail },
      ],
    },
    {
      items: [
        {
          label: "CRO Start Date",
          value: startDate,
        },
        {
          label: "Protocol Date",
          value: protocolDate,
        },
      ],
    },
  ];
  return (
    <SepareteContainer>
      <HeaderContainer>
        <SectionHeader paddingBottom={false}>Study Details</SectionHeader>

        {canEditPlan && (
          <Button
            style={{ color: "var(--White)" }}
            onClick={() =>
              history.push(`/ctpo/study-plans/${id}/${planId}#details`)
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </HeaderContainer>
      <div style={{ padding: "20px" }}>
        <Container>
          <Content>
            {sections.map((section, index) => (
              <div key={index}>
                {section.items.map(({ label, value }, idx) => (
                  <Label
                    key={idx}
                    label={label}
                    value={value}
                    color="black"
                  />
                ))}
              </div>
            ))}
          </Content>
        </Container>
      </div>
    </SepareteContainer>
  );
};

export default PrimaryDetails;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  margin-top: -2em;
`;
const Content = styled.div`
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--Primary-color);
  border-radius: 4px;
  margin-bottom: 3em;
`;
