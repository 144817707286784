import { Button, LinearProgress } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "../../../../app/components";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { makeStyles } from "@material-ui/styles";

export const DeleteStudyPlanModal = ({
  deleteOpen,
  setDeleteOpen,
  scenarioStatus,
  studyPlanId,
}) => {
  const classes = useStyles();
  const { fetchStudy, deleteStudy } = useStudyPlan();
  const [studyError, setStudyError] = useState(false);
  const [deletingStudy, setDeletingStudy] = useState(false);

  const { id } = useParams();
  const { showSnackbar } = useSnackbar();

  const redirect = async () => {
    await fetchStudy(id);
  };

  const deleteMessage = useMemo(() => {
    if (studyError)
      return "Enrollment Plan cannot be deleted. Please contact the Creator of the Enrollment Plan to delete and confirm the status is 'Draft'.";
    return `
        Are you sure you want to delete this Enrollment Plan?  This cannot be un-done and will remove all information related to this Enrollment Plan.
        `;
  }, [studyError]);

  const deleteStudyHandler = async () => {
    try {
      setDeletingStudy(true);
      if (!["Draft", "Draft-Combo"].includes(scenarioStatus))
        throw new Error("Failed to Delete Enrollment Plan");
      const res = await deleteStudy(id, studyPlanId);
      if (res.data.length && res.data[0].returnCode === "200") {
        setDeletingStudy(false);
        setDeleteOpen(false);
        showSnackbar("Deleted successfully", "success");
        redirect();
      } else throw new Error("Failed to Delete Enrollment Plan");
    } catch (err) {
      console.log(err);
      setStudyError(true);
      setDeletingStudy(false);
      showSnackbar("Failed to Delete Enrollment Plan", "error");
    }
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
    setStudyError(false);
  };

  return (
    <Modal
      open={deleteOpen}
      onClose={closeDeleteModal}
      aria-labelledby="Delete Enrollment Plan"
      aria-describedby="Delete an Enrollment Plan"
      title="Delete Enrollment Plan"
    >
      <Container>
        {deletingStudy ? (
          <LinearProgress />
        ) : (
          <Message>{deleteMessage}</Message>
        )}
      </Container>
      <SideBySide>
        <Button
          className={classes.secondaryButton}
          autoFocus
          onClick={closeDeleteModal}
          disabled={deletingStudy}
        >
          {studyError ? "Close" : "Cancel"}
        </Button>
        {!studyError && (
          <Button
            className={classes.primaryButton}
            disabled={deletingStudy}
            onClick={deleteStudyHandler}
          >
            DELETE
          </Button>
        )}
      </SideBySide>
    </Modal>
  );
};

const Message = styled.p`
  text-align: center;
  padding-top: 0rem;
`;

const Container = styled.div`
  display: grid;
  padding: 1rem;
  grid-gap: 2em;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));
