import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditScheduleOfEventModal from "../../../schedule.events.table/table.row/edit.schedule.event.modal";
import UnsavedChangesModal from "../../soe/unsaved.changes.modal";
import UploadSOEProtocolModal from "./upload.soe.potocol.modal";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";

const Controls = ({
  errors,
  editOpen,
  setEditOpen,
  hasChangesToSave,
  setUpdateContext,
  saveSOE,
  value,
}) => {
  const history = useHistory();
  const { id, scheduleId } = useParams();
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const {
    hasSOEToSave,
    handleSOESave,
    loadingSOEStudy,
    checkSOEValid,
    fetchSOEVisits,
    fetchSOEAssessments,
    fetchSOEMapping,
    setScheduleOfEventsVisitsChangeLog,
    setScheduleOfEventsAssessmentsChangeLog,
    setVisitsAndAssessmentsMappingChangeLog,
    updateSOE,
    fetchSOEStudy,
    fetchSOECountries,
    fetchRiskAssessments,
    fetchVisitSampleStrategy,
    setScheduleOfEventsVisits,
    currentSOE: soe,
  } = useScheduleEvents();

  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  const resetChanges = () => {
    setScheduleOfEventsVisitsChangeLog({});
    setScheduleOfEventsAssessmentsChangeLog({});
    setVisitsAndAssessmentsMappingChangeLog({});
  };

  const handleBackToList = () => {
    resetChanges();
    history.push(`/ctpo/study-plans/${id}`);
  };

  const handleCancel = () => {
    setUnsavedModalOpen(true);
  };

  const callback = async (withoutLoading = false) => {
    await setTimeout(async () => {
      await fetchSOEVisits(scheduleId, id, null, null, null, withoutLoading);
      await fetchSOEAssessments(
        scheduleId,
        id,
        null,
        null,
        null,
        withoutLoading,
      );
      await fetchSOEMapping(scheduleId, withoutLoading);
      await fetchSOECountries(scheduleId);
      await fetchRiskAssessments(scheduleId);
      await fetchVisitSampleStrategy(scheduleId);
      await fetchSOEStudy(id);
    }, 200);
  };

  const handleSave = async (validate = true) => {
    try {
      let [formsValid, lErrorMessages] = checkSOEValid();
      if (!formsValid && validate) {
        showSnackbar(
          <ul>
            {Object.keys(lErrorMessages).map((key) => {
              return (
                <li key={key}>
                  {lErrorMessages[key].message}{" "}
                  {lErrorMessages[key].count > 1 && (
                    <ErrorMessageCount>
                      {lErrorMessages[key].count}
                    </ErrorMessageCount>
                  )}
                </li>
              );
            })}
          </ul>,
          "error",
        );
      } else {
        localStorage.setItem("temporal-save-flag", true);
        setScheduleOfEventsVisits((prevScheduleOfEventsVisits) => {
          const updatedStudy = prevScheduleOfEventsVisits.study.map((event) => {
            if (
              event.scheduleOfEventsVisitId &&
              String(event.scheduleOfEventsVisitId).startsWith("1000")
            ) {
              return {
                ...event,
                scheduleOfEventsVisitId: undefined,
              };
            }
            return event;
          });

          return {
            ...prevScheduleOfEventsVisits,
            study: updatedStudy,
          };
        });
        await handleSOESave(id, scheduleId, callback);
        showSnackbar("Saved successfully", "success");
        return true;
      }
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
    return false;
  };

  useEffect(() => {
    if (saveSOE && localStorage.getItem("temporal-save-flag") === null) {
      handleSave();
      setUpdateContext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSOE]);

  const handleForecastSOE = () => {
    history.push(`/ctpo/study-plans/${id}/soe/${scheduleId}/forecast`);
  };

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const updateSOEHandler = async (soeValue) => {
    try {
      await updateSOE(soeValue, redirect);
      showSnackbar("Saved successfully", "success");
      setEditOpen((prev) => !prev);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
  };

  return (
    <Container>
      <Button
        onClick={handleBackToList}
        startIcon={<ChevronLeftIcon />}
        style={{
          justifyContent: "flex-start",
          paddingLeft: 0,
          color: "var(--Primary-color)",
        }}
      >
        <span style={{ marginLeft: "auto" }}>Back to SOE List</span>
      </Button>

      <ButtonContainer>
        <Button
          className={classes.secondaryButton}
          onClick={handleCancel}
          disabled={(!hasSOEToSave && !hasChangesToSave) || loadingSOEStudy}
        >
          Cancel
        </Button>
        <Button
          className={classes.secondaryButton}
          onClick={() => setEditOpen(true)}
        >
          Edit
        </Button>
        <Button
          disabled={(!hasSOEToSave && !hasChangesToSave) || loadingSOEStudy}
          className={classes.primaryButton}
          onClick={() => {
            if (value === 0) {
              localStorage.removeItem("temporal-save-flag");
              setUpdateContext(true);
            } else {
              handleSave();
            }
          }}
        >
          Save
        </Button>
        <Button
          className={classes.primaryButton}
          onClick={() => setFileUploadOpen(true)}
        >
          Upload
        </Button>
        <Button
          className={classes.secondaryButton}
          onClick={handleForecastSOE}
        >
          REPORTS
        </Button>
      </ButtonContainer>
      {editOpen && (
        <EditScheduleOfEventModal
          soe={{ scheduleOfEventsId: scheduleId }}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onContinue={updateSOEHandler}
        />
      )}
      {fileUploadOpen && (
        <UploadSOEProtocolModal
          soe={soe}
          open={fileUploadOpen}
          onClose={() => setFileUploadOpen(false)}
          onContinue={updateSOEHandler}
          fetchSOEValues={callback}
        />
      )}

      {
        <UnsavedChangesModal
          open={unsavedModalOpen}
          onToggle={() => setUnsavedModalOpen(!unsavedModalOpen)}
          handleSave={handleSave}
          handleBackToList={handleBackToList}
        />
      }
    </Container>
  );
};

export default Controls;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;

const ErrorMessageCount = styled.span`
  display: inline-block;
  font-size: 12px;
  padding: 0em 0.6em;
  border: 1px solid red;
  border-radius: 0.8em;
  color: red;
`;

const PrimaryButton = styled.button`
  border: 1px solid var(--White);
  color: var(--White);
  background-color: var(--Primary-color);
`;
