export const regionColors = ["#13C2C2", "#5BD5D5", "#AEEAEA", "#E7F9F9"];
export const timelineColors = ["#722ED1", "#FA8C16", "#1890FF", "#EB2F96"];
export const siteGrpColors = [
  "#08979C",
  "#0CA7AA",
  "#10B8B9",
  "#13C2C2",
  "#5BD5D5",
  "#AEEAEA",
  "#E7F9F9",
  "#08979C",
  "#0CA7AA",
  "#10B8B9",
  "#13C2C2",
  "#5BD5D5",
  "#AEEAEA",
  "#E7F9F9",
  "#08979C",
  "#0CA7AA",
  "#10B8B9",
  "#13C2C2",
  "#5BD5D5",
  "#AEEAEA",
  "#E7F9F9",
  "#08979C",
  "#0CA7AA",
  "#10B8B9",
  "#13C2C2",
  "#5BD5D5",
  "#AEEAEA",
  "#E7F9F9",
  "#08979C",
  "#0CA7AA",
  "#10B8B9",
  "#13C2C2",
  "#5BD5D5",
  "#AEEAEA",
  "#E7F9F9",
  "#08979C",
  "#0CA7AA",
  "#10B8B9",
  "#13C2C2",
  "#5BD5D5",
  "#AEEAEA",
  "#E7F9F9",
];
