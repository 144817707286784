import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { Title } from "../../../app/components";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useFavorite } from "../../../app/contexts/favorites.context";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useUser } from "../../../app/contexts/user.context";
import StudyDetailsModal from "../../study.details.modal";

const Header = ({ showDetailsButton = false }) => {
  const { study } = useStudyPlan();
  const { isFavorite, removeFavorite, createFavorite } = useFavorite();
  const descClasses = useDescStyles();
  const iconClasses = useIconStyles();
  const disabledClasses = useDisabledStyles();
  const { user } = useUser();
  const [studyDetailsViewOpen, setStudyDetailsViewOpen] = useState(false);

  return (
    <Container>
      <SideBySide>
        <LeftSide>
          <Title>
            {study?.sponsorName} - {study?.projectId}
          </Title>
          {showDetailsButton && (
            <Button onClick={() => setStudyDetailsViewOpen(true)}>
              STUDY DETAILS
            </Button>
          )}
          <StudyDetailsModal
            studyDetailsViewOpen={studyDetailsViewOpen}
            setStudyDetailsViewOpen={setStudyDetailsViewOpen}
          />
        </LeftSide>

        {isFavorite({ id: study?.studyId }) ? (
          <IconButton
            onClick={() =>
              removeFavorite({
                studyId: study?.studyId,
                userId: user.userId,
              })
            }
          >
            <StarIcon classes={iconClasses} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() =>
              createFavorite({
                studyId: study?.studyId,
                projectId: study?.projectId,
                userId: user.userId,
                isFavorite: 1,
                studyName: study?.studyName,
              })
            }
          >
            <StarBorderIcon classes={disabledClasses} />
          </IconButton>
        )}
      </SideBySide>
      <Typography classes={descClasses}>{study?.studyName}</Typography>
      <Divider />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  background-color: var(--Primary-color);
  padding-left: 1em;
  border-radius: 4px;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled.div`
  display: flex;
  gap: 2em;
`;

const Button = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  color: var(--White);
  font-weight: bold;
  border-radius: 4px;
  font-size: small;
  padding-inline: 0.5em;
  height: 28px;
  margin-top: 7px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 0.3s;
  }
`;

const useDescStyles = makeStyles((theme) => ({
  root: {
    color: "var(--White)",
    marginBottom: "1em",
  },
}));

const useIconStyles = makeStyles((theme) => ({
  root: {
    fill: "var(--White)",
  },
}));

const useDisabledStyles = makeStyles((theme) => ({
  root: {
    fill: "var(--White)",
  },
}));
