import { Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CloneScheduleOfEvent from "./clone.schedule.event.modal";
import EditScheduleOfEventModal from "./edit.schedule.event.modal";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MuiTableRow from "@material-ui/core/TableRow";
import OptionsMenu from "./options.menu";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useScheduleEvents } from "../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../app/contexts/snackbar.context";
import { otherStyles } from "../../../app/helpers/utils.js";
import { withStyles } from "@material-ui/core";
import { DeleteScheduleEventModal } from "./delete.schedule.event.modal/delete.schedule.event.modal.js";

const TableCellContent = ({ content, formatDate, noWrap, title }) => {
  const parsedDate = moment.utc(content);

  const formattedDate = parsedDate.isValid()
    ? parsedDate.format("DD/MMM/YYYY")
    : "";

  return (
    <TableCell
      align="left"
      style={{ padding: 5 }}
    >
      <Typography
        noWrap={noWrap}
        title={title}
      >
        {formatDate ? formattedDate : content}
      </Typography>
    </TableCell>
  );
};

const TableRow = (props) => {
  const {
    soe,
    studyPlans,
    scheduleOfEventsId,
    scheduleOfEventsName,
    insertBy,
    updateDate,
    scheduleOfEventsDescr,
    scheduleOfEventsStatus,
    studyPlanId,
    scheduleOfEvents,
    savingSOEStudy,
    updateBy,
    insertDate,
    protocolDate,
    protocolVersion,
  } = props;

  const [editOpen, setEditOpen] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const otherClasses = otherStyles();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const { updateSOE, fetchSOEStudy } = useScheduleEvents();

  const { showSnackbar } = useSnackbar();

  const studyPlanName = useMemo(() => {
    if (!studyPlanId) return "";
    const filteredStudies = studyPlans.filter(
      (study) => study.studyPlanId === studyPlanId,
    );
    return filteredStudies.length > 0 ? filteredStudies[0].studyPlanName : "";
  }, [studyPlans, studyPlanId]);

  const history = useHistory();
  const { id } = useParams();

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const StyledTableCell = withStyles((theme) => ({
    root: {
      paddingLeft: "5px",
      paddingRight: "5px",
      color: "var(--Secondary-color)",
      fontWeight: "bold",

      "&:first-child": {
        paddingLeft: "10px",
      },

      "&:hover": {
        fontWeight: "bold",
        opacity: 0.7,
        color: "var(--Secondary-color)",
      },
    },
  }))(TableCell);

  const handleClickStudy = () => {
    history.push(`/ctpo/study-plans/${id}/soe/${scheduleOfEventsId}`);
  };

  const updateSOEHandler = async (soeValue) => {
    try {
      await updateSOE(soeValue, redirect);
      showSnackbar("Saved successfully", "success");
      setEditOpen((prev) => !prev);
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
  };

  if (!scheduleOfEventsId || !studyPlans) {
    return null;
  }

  const tableData = [
    { content: scheduleOfEventsDescr },
    { content: protocolVersion },
    { content: protocolDate, formatDate: "DD/MMM/YYYY" },
    { content: scheduleOfEventsStatus },
    { content: studyPlanName },
    { content: insertBy },
    {
      content: insertDate,
      formatDate: "DD/MMM/YYYY",
      noWrap: true,
    },
    { content: updateBy },
    {
      content: updateDate,
      formatDate: "DD/MMM/YYYY",
      noWrap: true,
    },
  ];

  return (
    <MuiTableRow>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell
        className={otherClasses.link}
        onClick={handleClickStudy}
        align="left"
      >
        {scheduleOfEventsName}
      </StyledTableCell>

      {tableData.map((data, index) => (
        <TableCellContent
          key={index}
          content={data.content}
          formatDate={data.formatDate}
          noWrap={data.noWrap}
        />
      ))}

      <TableCell
        align="right"
        padding="checkbox"
      >
        <IconButton onClick={handleClick}>
          <MoreVertIcon classes={classes} />
        </IconButton>
        <OptionsMenu
          setEditOpen={setEditOpen}
          setCloneOpen={setCloneOpen}
          setDeleteOpen={setDeleteOpen}
          open={open}
          anchor={anchor}
          scheduleOfEventsId={scheduleOfEventsId}
          onClose={() => setOpen(false)}
        />
      </TableCell>

      {editOpen && (
        <EditScheduleOfEventModal
          soe={soe}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onContinue={updateSOEHandler}
        />
      )}
      {cloneOpen && (
        <CloneScheduleOfEvent
          open={cloneOpen}
          onClose={() => setCloneOpen(false)}
          soe={{ scheduleOfEventsName, scheduleOfEventsId, scheduleOfEvents }}
        />
      )}
      {deleteOpen && (
        <DeleteScheduleEventModal
          deleteOpen={deleteOpen}
          savingSOEStudy={savingSOEStudy}
          setDeleteOpen={setDeleteOpen}
          scheduleOfEventsId={scheduleOfEventsId}
          redirect={redirect}
          id={id}
        />
      )}
    </MuiTableRow>
  );
};

export default TableRow;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#757072",
  },
}));
