import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";
import SepareteContainer from "../../../../../app/components/separete.Container";

const SiteFatigue = (props) => {
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;

  let renderScenario;
  let typographyText;

  if (studyPlanType === SCENARIO_A) {
    renderScenario = <ScenarioA {...props} />;
    typographyText =
      " Site Fatigue or a time-based erosion factor for enrollment can be applied using the below fields. This should be only applied when you expect the enrollment rate to decrease after a period of time. First, complete the number of days after site activation when site fatigue is expected to start. In the second field include the new lower enrollment rate that you expect.";
  }

  if (studyPlanType === SCENARIO_B) {
    renderScenario = <ScenarioB {...props} />;
    typographyText =
      "Site Fatigue or a time-based erosion factor for enrollment can be applied using the below fields. This should be only applied when you expect the enrollment rate to decrease after a period of time. First, complete the number of days after site activation when site fatigue is expected to start. In the second field include the percent (in decimals) of the former rate that is expected. Enter 0.75 if you expect the rate to be 75% of what it formally was.";
  }

  return (
    <SepareteContainer>
      <Container id="site-fatigue">
        <SectionHeader paddingBottom={true}>Site Fatigue</SectionHeader>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          <Typography>{typographyText}</Typography>
          <Content>{renderScenario}</Content>
        </div>
      </Container>
    </SepareteContainer>
  );
};

export default SiteFatigue;

const Container = styled.div``;
const Content = styled.div`
  margin-top: 1em;
`;
