import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/styles";
import ExportTablesModal from "./export.tables.modal";
import Button from "@material-ui/core/Button";

const TableControls = ({ detailsView, type }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Button
        className={classes.secondaryButton}
        onClick={() => setOpen(true)}
      >
        Export Tables
      </Button>

      <ExportTablesModal
        open={open}
        detailsView={detailsView}
        onClose={() => setOpen(false)}
        type={type}
      />
    </Container>
  );
};

export default TableControls;

const useStyles = makeStyles((theme) => ({
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  width: 100%;
`;
