import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "../../../../app/components";
import { makeStyles } from "@material-ui/core/styles";

const UnsavedChangesModal = ({
  open = false,
  onToggle,
  to,
  handleSave,
  handleBackToList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const handleSaveAndExit = async () => {
    const saved = await handleSave();
    if (saved) redirect();
    else onToggle();
  };

  const handleExitWithoutSaving = async () => {
    handleBackToList();
  };

  const redirect = () =>
    to ? history.push(to) : history.push(`/ctpo/study-plans/${id}`);

  return (
    <Modal
      open={open}
      onClose={onToggle}
      aria-labelledby="clone study plan"
      aria-describedby="clone a study plan"
      title="You Have Unsaved Changes"
    >
      <Typography>
        Are you sure you want to exit without saving your changes first? Select
        an action below to continue.
      </Typography>
      <Container>
        <Button
          className={classes.secondaryButton}
          onClick={handleExitWithoutSaving}
        >
          Exit Without Saving
        </Button>
        <Button
          className={classes.primaryButton}
          onClick={handleSaveAndExit}
        >
          Save & Exit
        </Button>
      </Container>
    </Modal>
  );
};

export default UnsavedChangesModal;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));

const Container = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
