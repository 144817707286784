import React, { useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import OptionsMenu from "./options.menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const TopControls = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, planId } = useParams();
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const { clearSimData } = useStudyPlan();

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleBackToList = () => {
    clearSimData();
    history.push(`/ctpo/study-plans/${id}`);
  };

  const handleEdit = () => {
    clearSimData();
    history.push(`/ctpo/study-plans/${id}/${planId}/review`);
  };

  return (
    <Container>
      <Button
        style={{ color: "var(--Primary-color)" }}
        onClick={handleBackToList}
      >
        <ChevronLeftIcon />
        Back to Study Plan List
      </Button>

      <ButtonContainer>
        <Button
          className={classes.secondaryButton}
          onClick={handleEdit}
        >
          Edit Study Plan
        </Button>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <Button
              className={classes.primaryButton}
              onClick={handleClick}
            >
              Save As <ArrowDropDownIcon />
            </Button>
            <OptionsMenu
              open={open}
              anchor={anchor}
              onClose={() => setOpen(false)}
              onBlur={() => setOpen(false)}
            />
          </div>
        </ClickAwayListener>
      </ButtonContainer>
    </Container>
  );
};

export default TopControls;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;
