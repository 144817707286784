import React, { useMemo } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./chart.css";
import { siteGrpColors } from "../colors";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { nanoid } from "nanoid";
import { makeStyles } from "@material-ui/core";

const CohortChartMonthly = () => {
  const classes = useStyles();
  const { simulationResults } = useStudyPlan();
  const { cohortChartMonthly: data } = simulationResults;
  const colors = siteGrpColors;
  const [groups, barKeys, rerenderKey] = useMemo(() => {
    let groups = [...(data?.Group || [])];
    let bars = [...(data?.Bars || [])];
    groups = groups.map((group) => {
      let g = { ...group };
      bars.forEach((barKey) => {
        let newBarKey = barKey.replaceAll("_", " ");
        if (newBarKey !== barKey) {
          g[newBarKey] = g[barKey];
          delete g[barKey];
        }
      });
      return g;
    });
    bars = bars.map((barKey) => barKey.replaceAll("_", " "));
    return [groups, bars, nanoid()];
  }, [data]);

  if (!data) return null;

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart
          key={rerenderKey}
          margin={{ top: 40, right: 15, bottom: 75, left: 15 }}
          data={groups}
          style={{ alignSelf: "center" }}
        >
          <text
            x="50%"
            y={15}
            fill="#212121"
            textAnchor="middle"
            dominantBaseline="central"
            className={classes.title}
            fontFamily="Arial"
          >
            Patients By Group
          </text>
          <Tooltip wrapperStyle={{ fontFamily: "sans-serif" }} />
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <XAxis
            dataKey="date"
            fontFamily="sans-serif"
            scale="band"
            angle={-90}
            dy={35}
            dx={-5}
            interval={0}
          />
          <YAxis
            interval={0}
            label={{
              fontFamily: "sans-serif",
              value: "Patients",
              angle: -90,
              position: "insideLeft",
              fill: "#666666",
            }}
            domain={["dataMin", "dataMax + 5"]}
            allowDataOverflow
            fontFamily="Arial"
            yAxisId="1"
            type="number"
          />
          {barKeys.map((key, index) => (
            <Bar
              yAxisId="1"
              stackId="a"
              dataKey={key}
              key={key}
              barSize={20}
              fill={colors[index]}
            />
          ))}
          <Line
            yAxisId="1"
            type="monotone"
            dataKey="Enrolled"
            stroke="#FA8C16"
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: undefined,
    color: "#212121",
  },
}));

export default CohortChartMonthly;