import React, { Fragment } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStudyPlan } from "../../app/contexts/study.context";
import moment from "moment";
import ViewSiteListModal from "./ViewSiteListModal";
import { makeStyles } from "@material-ui/core/styles";

const Controls = ({ search = "", onSearch, clearSearch }) => {
  const classes = useStyles();
  const [remodelViewOpen, setRemodelViewOpen] = React.useState(false);
  const { studyActualsData, loadStudyActuals, lActualsLoading } =
    useStudyPlan();
  const { actualsData = [] } = studyActualsData;
  const history = useHistory();
  const { id } = useParams();

  const handleRemodel = () => {
    history.push(`/ctpo/study-actuals/${id}/remodel`);
  };

  const checkRemodelRun = () => {
    if (actualsData.length > 0 && moment(actualsData[0].toolTips).isValid()) {
      return (
        (moment().diff(moment(actualsData[0].toolTips)) +
          new Date().getTimezoneOffset() * 60 * 1000) /
          3600000 >=
        24
      );
    }
    return true;
  };

  return (
    <Container>
      {actualsData.length > 0 ? (
        <Fragment>
          {lActualsLoading ? (
            <CircularProgress
              color="white"
              size="2em"
            />
          ) : (
            <Fragment>
              <Button
                disabled={checkRemodelRun()}
                onClick={handleRemodel}
                className={classes.primaryButton}
              >
                REMODEL
              </Button>
              <Button
                disabled={checkRemodelRun()}
                onClick={() => setRemodelViewOpen(true)}
                className={classes.primaryButton}
              >
                VIEW SITE LIST
              </Button>
              <Button
                onClick={loadStudyActuals}
                className={classes.primaryButton}
              >
                IMPORT ACTUALS
              </Button>

              <ViewSiteListModal
                remodelViewOpen={remodelViewOpen}
                setRemodelViewOpen={setRemodelViewOpen}
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div></div>
      )}
    </Container>
  );
};
export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 1em;
  align-content: center;
`;

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    border: "1px solid var(--White)",
    color: "var(--White)",
    backgroundColor: "var(--Primary-color)",
    "&:hover": {
      backgroundColor: "var(--Primary-color)",
      opacity: "0.8",
    },
    "&:disabled": {
      border: "1px solid var(--Grey-4)",
      color: "var(--Grey-5)",
      backgroundColor: "var(--Grey-4)",
      cursor: "not-allowed",
    },
  },
}));
