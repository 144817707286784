import Divider from "@material-ui/core/Divider";
import FavoriteStudy from "./favorite.studies";
import Paper from "./paper";
import React from "react";
import Search from "./search";
import { Title } from "../app/components";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "15px",
  },
})(Tooltip);

const ColoredInfoIcon = styled(InfoIcon)`
  color: var(--Secondary-color);
`;

const StudyPlanSearch = () => {
  return (
    <Container>
      <nav>
        <Paper>
          <PaperContent>
            <TitleContainer>
              <Title
                color="var(--Secondary-color)"
                size="small"
              >
                Select a Study or Opportunity
              </Title>
              <CustomTooltip title="You can search by PCN, Sponsor Name, or Indication">
                <IconButton style={{ marginBottom: "5px" }}>
                  <ColoredInfoIcon fontSize="medium" />
                </IconButton>
              </CustomTooltip>
            </TitleContainer>
            <SearchWrapper>
              <Search />
            </SearchWrapper>
            <FavoriteStudy height="calc(-465px + 100vh)"/>
          </PaperContent>
        </Paper>
      </nav>
    </Container>
  );
};

export default StudyPlanSearch;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ hasMargin }) => (hasMargin ? "5%" : "0")};
  position: relative;
`;

const PaperContent = styled.div`
  z-index: 1;
  min-height: 100vh;
  min-height: calc(100vh - 145px);
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: auto;
`;
const SearchWrapper = styled.div`
  margin-bottom: 20px;
`;