import { SectionHeader, Tooltip } from "../../../../../app/components";
import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { checkNumberLength } from "../../../utils/helpers";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import SepareteContainer from "../../../../../app/components/separete.Container";

const renderMonthHeaders = () =>
  [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ].map((month) => (
    <Cell
      key={month}
      variant="header"
    >
      <Typography>
        <strong>{month}</strong>
      </Typography>
    </Cell>
  ));

const useInputStyles = makeStyles(() => ({
  root: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
    "& input[type=number]": {
      textAlign: "center",
      "-moz-appearance": "textfield",
    },
  },
}));

const validateAllZero = (variations) => {
  return variations.every(
    (item) => parseFloat(item.enrollmentSeasonalVariation) === 0,
  );
};
const SiteGroupActivationProfiles = ({ country, siteGroup, setHasErrors }) => {
  const inputClasses = useInputStyles();
  const { updateCountries } = useStudyPlan();
  const { siteGroupId, enrollmentVariations } = siteGroup;
  const { countryId } = country;

  const [localEnrollmentVariations, setLocalEnrollmentVariations] = useState(
    siteGroup.enrollmentVariations,
  );

  const [isAllZero, setIsAllZero] = useState(
    validateAllZero(siteGroup.enrollmentVariations),
  );

  useEffect(() => {
    setIsAllZero(validateAllZero(localEnrollmentVariations));
  }, [localEnrollmentVariations]);

  useEffect(() => {
    setHasErrors((prevErrors) => {
      const errorMessage = "Cannot all be zero.";
      if (isAllZero) {
        return prevErrors.includes(errorMessage)
          ? prevErrors
          : [...prevErrors, errorMessage];
      } else {
        return prevErrors.filter((error) => error !== errorMessage);
      }
    });
  }, [isAllZero]);

  const handleChange = (value, index) => {
    const newEnrollmentVariations = [...localEnrollmentVariations];
    newEnrollmentVariations[index].enrollmentSeasonalVariation = value;

    setLocalEnrollmentVariations(newEnrollmentVariations);
    const update = { enrollmentVariations: newEnrollmentVariations };
    updateCountries(countryId, siteGroupId, update);
  };

  return (
    <SepareteContainer>
      <Container>
        <SectionHeader
          endAdornment={
            <Tooltip
              info="Seasonal enrollment is modeled using the below table that contains a percentage for each month of the year. This allows the model to factor in when screening may be slower (e.g., August in Europe) as well as when an indication has a specific seasonal activity profile (e.g., incidence of the flu). The regular screening rate would correspond to a 100% or ‘1’ entry in the table for that month, and slower screening would then be modeled with a lower value (e.g., 0.85 or 85% of the regular screening rate)."
              changeColor={true}
            />
          }
        >
          Country Enrollment Seasonal Variations
        </SectionHeader>
        <div style={{ padding: "20px" }}>
          <Table>
            <Row>{renderMonthHeaders()}</Row>
            <Row>
              {enrollmentVariations.map((item, index) => (
                <Cell key={item.seasonalEnrollmentVariationId}>
                  <TextField
                    type="number"
                    classes={inputClasses}
                    value={item.enrollmentSeasonalVariation ?? ""}
                    onChange={(event) => {
                      let value = event.target.value;
                      if (value.includes("-")) {
                        handleChange("0", index);
                        return;
                      }
                      if (!checkNumberLength(value, 4, 2, 1, true, true))
                        return;
                      handleChange(value, index);
                    }}
                    variant="outlined"
                    size="small"
                    required
                  />
                </Cell>
              ))}
            </Row>
          </Table>
        </div>
      </Container>
    </SepareteContainer>
  );
};

export default SiteGroupActivationProfiles;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--Grey-1)" : "var(--White)"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid #ccc;
  &:last-child {
    border: none;
  }
  padding: 5px;
`;

const Table = styled.div`
  display: grid;
  gridtemplaterows: auto auto;
  border: 1px solid var(--Grey-4);
  border-radius: 4px;
`;
