import Button from "@material-ui/core/Button";
import React, { useMemo } from "react";
import styled from "styled-components";
import Label from "../study.plan/soe/header/label";
import { Modal } from "../../app/components";
import { useScheduleEvents } from "../../app/contexts/schedule.events.context";
import { useStudyPlan } from "../../app/contexts/study.context";
import { makeStyles } from "@material-ui/styles";

const LabelsGroup = ({ labels }) => (
  <VerticalContainer>
    {labels.map(({ label, value }) => (
      <Label
        key={label}
        label={label}
        value={value}
        color="black"
      />
    ))}
  </VerticalContainer>
);

const StudyDetailsModal = ({
  studyDetailsViewOpen,
  setStudyDetailsViewOpen,
}) => {
  const { currentSOE } = useScheduleEvents();
  const classes = useStyles();
  const { study } = useStudyPlan();
  const {
    studyPlans,
    projectId,
    sponsorName,
    proposalVersion,
    proposalDueDate,
    therapeuticArea,
    indication,
    investigationalProductName,
    phase,
    protocolId,
    therapeuticFranchise,
    proposalBudgeterName,
    proposalStrategyName,
  } = study || {};

  const { scheduleOfEventsName, scheduleOfEventsDescr, studyPlanId } =
    currentSOE || {};

  const studyPlanName = useMemo(
    () =>
      studyPlans.find((sp) => sp.studyPlanId === studyPlanId)?.studyPlanName,
    [studyPlans, studyPlanId],
  );

  const labelsGroup1 = [
    { label: "Linked Enrollment Plan (Study Plan)", value: studyPlanName },
    { label: "Proposal Due Date", value: proposalDueDate },
    { label: "Product Name", value: investigationalProductName },
    { label: "Sponsor", value: sponsorName },
    { label: "PCN", value: projectId },
  ];

  const labelsGroup2 = [
    { label: "SOE Name", value: scheduleOfEventsName },
    { label: "Therapeutic Area", value: therapeuticArea },
    { label: "Indication", value: indication },
    { label: "Proposal Version", value: proposalVersion },
    { label: "Proposal Budgeter", value: proposalBudgeterName },
  ];

  const labelsGroup3 = [
    { label: "SOE Description", value: scheduleOfEventsDescr },
    { label: "Protocol Number", value: protocolId },
    { label: "Therapeutic Franchise", value: therapeuticFranchise },
    { label: "Strategy Lead", value: proposalStrategyName },
    { label: "Study Phase", value: phase },
  ];

  return (
    <Modal
      open={studyDetailsViewOpen}
      onClose={() => setStudyDetailsViewOpen(false)}
      aria-labelledby="Study Details"
      aria-describedby="Study Details"
      title="Study Details"
      root={{ padding: "0.5em 1em" }}
      sx={{
        height: "fit-content",
        width: "90vw",
      }}
    >
      <div style={{ width: "100%" }}>
        <MainContainer>
          <HorizontalContainer>
            <LabelsGroup labels={labelsGroup1} />
            <LabelsGroup labels={labelsGroup2} />
            <LabelsGroup labels={labelsGroup3} />
          </HorizontalContainer>
        </MainContainer>

        <Button
          onClick={() => setStudyDetailsViewOpen(false)}
          className={classes.secondaryButton}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default StudyDetailsModal;

const useStyles = makeStyles((theme) => ({
  secondaryButton: {
    border: "1px solid var(--Primary-color)",
    color: "var(--Primary-color)",
    backgroundColor: "var(--White)",
    width: "fit-content",
    margin: "1em",
    display: "block",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "var(--White)",
      opacity: "0.8",
    },
  },
}));

const MainContainer = styled.div`
  padding: 2em;
  flex: 1;
`;

const HorizontalContainer = styled.div`
  display: flex;
  gap: 5em;
  flex: 1;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex: 1;
`;
