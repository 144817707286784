import { FIELD_TYPES, VISIT_REQUIRED_FIELDS } from "../constants";
import React, { useMemo } from "react";

import { Typography, Button } from "@material-ui/core";
import styled from "styled-components";

export default function TableHeader({
  value,
  visitToUse,
  fieldType,
  setEditOpen,
  setOverheadPct,
}) {
  const commentType = useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "Visit Comments";
    if (fieldType === FIELD_TYPES.COST) return "Cost Comments";
    if (fieldType === FIELD_TYPES.MEDICAL) return "Clinical Strategy Comments";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT)
      return "Data Management Comments";
    return "";
  }, [fieldType]);

  return (
    <>
      <Row variant="header">
        <Cell
          width={value === 0 ? "12%" : "25%"}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Visit Category{" "}
              {VISIT_REQUIRED_FIELDS["visitCategory"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>
        <Cell
          width={value === 0 ? "18%" : "25%"}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Visit Name{" "}
              {VISIT_REQUIRED_FIELDS["visitName"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>
        <Cell
          width={value === 0 ? "6%" : "10%"}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Study {visitToUse?.visitIntervalType || "Day"}{" "}
              {VISIT_REQUIRED_FIELDS["visitStudyDay"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>
        <Cell
          width={value === 0 ? "6%" : "10%"}
          variant="header"
        >
          <Typography variant="body2">
            <strong>(+/-)</strong>
          </Typography>
        </Cell>
        <Cell
          width={value === 0 ? "6%" : "10%"}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Visit Window {visitToUse?.visitWindowIntervalType || "Day"}
            </strong>
          </Typography>
        </Cell>

        {(fieldType === FIELD_TYPES.COST || value !== 0) && (
          <>
            <Cell
              width={value === 0 ? "6%" : "10%"}
              variant="header"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="body2">
                  <strong>
                    Overhead %{" "}
                    {VISIT_REQUIRED_FIELDS["visitCost"] && (
                      <RequiredContainer>*</RequiredContainer>
                    )}
                  </strong>
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{
                    marginTop: "5px",
                    fontSize: "0.65rem",
                    padding: "2px 8px",
                    minWidth: "30px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    if (setOverheadPct) {
                      setOverheadPct();
                    }
                  }}
                  title={`"Apply To All" will apply the first value found to the rest of the cells in the column.`}
                >
                  Apply To All
                </Button>
              </div>
            </Cell>
            <Cell
              width={value === 0 ? "6%" : "10%"}
              variant="header"
            >
              <Typography variant="body2">
                <strong>Overhead </strong>
              </Typography>
            </Cell>
          </>
        )}
        {value === 0 && (
          <>
            {fieldType === FIELD_TYPES.MEDICAL && (
              <>
                <Cell
                  width="6%"
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>100% SDR/SDV Time (mins)</strong>
                  </Typography>
                </Cell>
                <Cell
                  width="6%"
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>CRA Sampled Visit</strong>
                  </Typography>
                </Cell>
                <Cell
                  width="6%"
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>Onsite SDR/SDV (mins)</strong>
                  </Typography>
                </Cell>
                <Cell
                  width="6%"
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>
                      CDA Review (mins){" "}
                      <EditButton onClick={() => setEditOpen(true)}>
                        {visitToUse.CDAReviewPctTime}
                      </EditButton>
                    </strong>
                  </Typography>
                </Cell>
              </>
            )}

            {fieldType === FIELD_TYPES.DATA_MANAGEMENT && (
              <>
                <Cell
                  width="6%"
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>Unique CRFs</strong>
                  </Typography>
                </Cell>
                <Cell
                  width="6%"
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>Non-unique CRFs</strong>
                  </Typography>
                </Cell>
              </>
            )}
            <Cell
              width={() => {
                switch (commentType) {
                  case "Visit Comments":
                    return "50%";
                  case "Cost Comments":
                    return "38%";
                  case "Clinical Strategy Comments":
                    return "26%";
                  case "Data Management Comments":
                    return "38%";
                  default:
                    return "0%";
                }
              }}
              variant="header"
            >
              <Typography variant="body2">
                <strong>{commentType}</strong>
              </Typography>
            </Cell>
          </>
        )}

        {value === 0 && (
          <Cell
            width="2%"
            variant="header"
          ></Cell>
        )}
      </Row>
    </>
  );
}

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--Grey-1)" : "var(--White)"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (typeof p.width === "function" ? p.width() : p.width)};
  border-right: 1px solid var(--Grey-4);
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid var(--Grey-4)" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--Grey-4)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--Grey-4)" : "")};
`;

const RequiredContainer = styled.span`
  color: red;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;
